import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();


  const showRegisterMessage = () => {
    toast.success('Please walk to the Registration counter to Register!', {
      position: 'top-center',
      autoClose: 4000, 
      hideProgressBar: true,
    });
  };


  const handleRegisterClick = () => {
    showRegisterMessage();
  };


  const isRegisterPage = location.pathname === '/register';

  return (
    <>
      <div className="bg-green-400 w-100 h-5"></div>
      <header className="text-gray-600 body-font border-2 border-b shadow-light transition-colors duration-300">
        <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center relative">
          <a
            className="flex order-first lg:order-none lg:w-1/5 title-font font-medium items-center text-gray-900 lg:items-center lg:justify-center mb-4 md:mb-0"
            style={{ flex: 1 }}
          >
            <center>
              <img
                src="/betca_logo_poster.png"
                alt="Logo"
                className="w-25 h-20 text-white p-2"
              />
            </center>
          </a>

          {!isRegisterPage && (
            <button
              onClick={handleRegisterClick}
              className="bg-green-500 hover:bg-indigo-600 text-white font-medium py-2 px-6 rounded-full mt-4 md:mt-0 ml-auto transform transition-transform duration-300 hover:scale-105"
            >
              Register
            </button>
          )}
        </div>
      </header>
      <ToastContainer
        position="top-center"
        autoClose={4000} 
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default Header;
