import React from 'react';

const Footer = () => {
  return (
    <>
      <footer className="flex flex-col items-center bg-neutral-200 text-center text-white dark:bg-neutral-600 mt-5">

        <div className="w-full bg-green-200 p-4 text-center text-neutral-700 dark:bg-neutral-700 dark:text-neutral-200">
          © 2023 Copyright:
          <a
            className="text-neutral-800 dark:text-neutral-400"
            href="https://betca.org"
            target='/'
          >
             Belagavi Technology Companies Association (BeTCA)
          </a>
        </div>
      </footer>
    </>
  );
};

export default Footer;
